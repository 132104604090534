import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import { Router } from 'next/router';
import Script from 'next/script';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { AuthProvider } from '@/components/providers/AuthProvider';
import { GTM_ID, sendPageViewEvent } from '../lib/gtm';
import '../styles/globals.css';
import { theme } from '../styles/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // for GTM
    const handleRouteChange = (url: string) => sendPageViewEvent(url);
    Router.events.on('routeChangeComplete', handleRouteChange);

    // for nProgress
    nProgress.configure({
      showSpinner: false,
    });

    const handleRouteStart = () => nProgress.start();
    const handleRouteDone = () => nProgress.done();
    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteDone);
    Router.events.on('routeChangeError', handleRouteDone);

    return () => {
      // for GTM
      Router.events.off('routeChangeComplete', handleRouteChange);

      // for nProgress
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteDone);
      Router.events.off('routeChangeError', handleRouteDone);
    };
  }, []);

  return (
    <RecoilRoot>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            {/* Google Tag Manager - Global base code */}
            <Script
              id='gtag-base'
              strategy='afterInteractive'
              dangerouslySetInnerHTML={{
                __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${GTM_ID}');
                `,
              }}
            />

            <Component {...pageProps} />
          </ChakraProvider>
        </QueryClientProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default MyApp;
