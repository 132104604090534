var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7eaf477327685c0982f321b7b00f0f9f7b21a216"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/**
 * @desc Sentry client
 * @license Copyright Trashare
 */
import { SentryConfig } from './sentry.config';

SentryConfig.init();
