import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { authStore, emailStore, UserProps } from '../store/auth';
import { UserStatusCodeType } from '@/types/codeType';
import { UserSettings } from '@/types/userSettings';
import { UserProfile } from '@/types/user';
import { Masters } from '@/consts/masters';
import { sendSetUserEvent } from 'lib/gtm';

type UseAuthContext = {
  signIn: (force?: boolean) => void;
  signOut: () => void;
  refreshSettings: (settings: UserSettings) => void;
  user?: UserProps;
  isInitializing: boolean;
  isActionRequired: boolean;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
};

// TODO: 全体的にuseEffectの最小化したい
// https://beta.reactjs.org/learn/you-might-not-need-an-effect
const useAuth = (): UseAuthContext => {
  const [auth, setAuth] = useRecoilState(authStore);
  // INFO: auth.userに定義するとステート管理が難しいため独立して管理
  const [_, setEmail] = useRecoilState(emailStore);
  const router = useRouter();
  const didLogRef = useRef(false);

  const signIn = useCallback((force: boolean = false) => {
    if (!force && auth.status !== 'initializing' && auth.status !== 'none') {
      return;
    }

    setAuth({
      status: 'authenticating',
    });
  }, []);

  const signOut = useCallback(() => {
    if (auth.status === 'none') {
      return;
    }

    setAuth({
      status: 'none',
    });
    setEmail(undefined);
  }, []);

  const refreshSettings = useCallback((settings: UserSettings) => {
    if (!auth.user) return;

    setAuth({
      status: auth.status,
      user: {
        uid: auth.user.uid,
        status: auth.user.status,
        settings: settings,
      },
    });
  }, []);

  // auto signin
  useEffect(() => {
    if (didLogRef.current) {
      return;
    }

    didLogRef.current = true;
    signIn();
  }, []);

  // execute sign in
  useEffect(() => {
    if (auth.status !== 'authenticating') {
      return;
    }

    const executeSignIn = async () => {
      try {
        const res = await fetch(`/api/users/me`);
        if (!res.ok) {
          throw res;
        }

        const result = await res.json();
        const userStatus = result.status as UserStatusCodeType;
        setAuth({
          status:
            userStatus === Masters.UserStatusCode.VALID_UNREGISTERED
              ? 'requiredInitialPayment'
              : userStatus === Masters.UserStatusCode.INVALID_EXPIRED
              ? 'requiredSubscriptionPayment'
              : 'authenticated',
          user: {
            uid: result.uid as string,
            status: result.status as UserStatusCodeType,
            settings: result.settings as UserSettings,
          },
        });

        await fetch(`/api/users/signedIn`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        signOut();
      }
    };

    executeSignIn();
  }, [auth]);

  // for requiredInitialPayment
  useEffect(() => {
    if (auth.status !== 'requiredInitialPayment') {
      // クレカを登録済みでプロフィール未登録であればプロフィール画面へ遷移させる
      if (router.pathname.startsWith('/top')) {
        const execProfile = async () => {
          const res = await fetch(`/api/users/profiles/me`);
          if (!res.ok) {
            throw res;
          }
          const result = await res.json();
          const profile = result.profile as UserProfile;
          // 姓、名、ニックネームでの値監視
          if (
            [profile.lastName, profile.firstName, profile.nickname].includes('')
          ) {
            router.push('/profile');
          }
        };
        execProfile();
      }

      return;
    }

    if (router.pathname.startsWith('/top')) {
      router.push('/signup/payment');
      return;
    }
  }, [auth]);

  // for requiredSubscriptionPayment
  useEffect(() => {
    if (auth.status !== 'requiredSubscriptionPayment') {
      return;
    }

    if (
      router.pathname.startsWith('/top') &&
      !router.pathname.startsWith('/action_required/payment')
    ) {
      router.push('/action_required/payment');
      return;
    }
  }, [auth]);

  // set userId to GA4 via GTM
  // FIXME: セッションスタートした時だけ、ユーザーID設定の処理をしたい
  useEffect(() => {
    if (auth.status === 'authenticated') {
      sendSetUserEvent(auth.user?.uid as string);
    }
  }, [auth]);

  return {
    signIn,
    signOut,
    refreshSettings,
    user: auth.user,
    isInitializing: auth.status === 'initializing',
    isActionRequired:
      auth.status === 'requiredInitialPayment' ||
      auth.status === 'requiredSubscriptionPayment',
    isAuthenticating: auth.status === 'authenticating',
    isAuthenticated: auth.status === 'authenticated',
  };
};

export default useAuth;
