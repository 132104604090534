export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID as string;

/** イベントタイプ */
type EventType =
  | 'pageview'
  | 'complete'
  | 'setUserId'
  | 'signup_mail'
  | 'signup_payment';

/** PageViewイベント送信処理 */
export const sendPageViewEvent = (url: string) => {
  sendEvent('pageview', { page: url });
};

/** Completeイベント送信処理 */
export const sendCompleteEvent = (email: string) => {
  sendEvent('complete', { mail_address: email });
};

/** SetUserイベント送信処理 */
export const sendSetUserEvent = (userId: string) => {
  sendEvent('setUserId', { userId });
};

/** SignupMailイベント送信処理 */
export const sendSignupMailEvent = () => {
  sendEvent('signup_mail');
};

/** SignupPaymentイベント送信処理 */
export const sendSignupPaymentEvent = () => {
  sendEvent('signup_payment');
};

/** イベント送信処理 */
const sendEvent = (event: EventType, params: Record<string, unknown> = {}) => {
  window.dataLayer.push({
    event,
    ...params,
  });
};

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
