/** 各種マスタ */
export namespace Masters {
  /** お問い合わせカテゴリ */
  export const ContactCategory = {
    '1': 'トレーニングシェアの使い方について',
    '2': 'トレーニングシェアの感想やフィードバック',
    '3': 'メディア掲載や取材について',
    '999': 'その他',
  } as const;

  /** メニューカテゴリコード */
  export const MenuCategoryCode = {
    /** スプリント */
    SPRINT: 'SPRINT',
    /** ミドル */
    MIDDLE: 'MIDDLE',
    /** ディスタンス */
    DISTANCE: 'DISTANCE',
  } as const;
  /** メニューカテゴリ */
  export const MenuCategory: Record<keyof typeof MenuCategoryCode, string> = {
    [MenuCategoryCode.SPRINT]: 'スプリント',
    [MenuCategoryCode.MIDDLE]: 'ミドル',
    [MenuCategoryCode.DISTANCE]: 'ディスタンス',
  } as const;

  /** 年代コード */
  export const AgeCode = {
    /** ジュニア */
    JUNIOR: 'JUNIOR',
    /** 中学生 */
    MIDDLE: 'MIDDLE',
    /** 大学生 */
    UNIVERSITY: 'UNIVERSITY',
    /** 社会人 */
    ADULT: 'ADULT',
  } as const;
  /** 年代 */
  export const Age: Record<keyof typeof AgeCode, string> = {
    [AgeCode.JUNIOR]: 'ジュニア',
    [AgeCode.MIDDLE]: '中高生',
    [AgeCode.UNIVERSITY]: '大学生',
    [AgeCode.ADULT]: '社会人',
  } as const;

  /** 性別コード */
  export const SexCode = {
    /** 男性 */
    MAN: 'MAN',
    /** 女性 */
    WOMAN: 'WOMAN',
    /** その他 */
    OTHER: 'OTHER',
    /** 回答しない */
    NONE: 'NONE',
  } as const;
  /** 性別 */
  export const Sex: Record<keyof typeof SexCode, string> = {
    [SexCode.MAN]: '男性',
    [SexCode.WOMAN]: '女性',
    [SexCode.OTHER]: 'その他',
    [SexCode.NONE]: '回答しない',
  } as const;

  /** 都道府県コード */
  export const PrefectureCode = {
    HOKKAIDO: '1',
    AOMORI: '2',
    IWATE: '3',
    MIYAGI: '4',
    AKITA: '5',
    YAMAGATA: '6',
    FUKUSHIMA: '7',
    IBARAKI: '8',
    TOCHIGI: '9',
    GUNMA: '10',
    SAITAMA: '11',
    CHIBA: '12',
    TOKYO: '13',
    KANAGAWA: '14',
    NIIGATA: '15',
    TOYAMA: '16',
    ISHIKAWA: '17',
    FUKUI: '18',
    YAMANASHI: '19',
    NAGANO: '20',
    GIFU: '21',
    SHIZUOKA: '22',
    AICHI: '23',
    MIE: '24',
    SHIGA: '25',
    KYOTO: '26',
    OSAKA: '27',
    HYOGO: '28',
    NARA: '29',
    WAKAYAMA: '30',
    TOTTORI: '31',
    SHIMANE: '32',
    OKAYAMA: '33',
    HIROSHIMA: '34',
    YAMAGUCHI: '35',
    TOKUSHIMA: '36',
    KAGAWA: '37',
    EHIME: '38',
    KOCHI: '39',
    FUKUOKA: '40',
    SAGA: '41',
    NAGASAKI: '42',
    KUMAMOTO: '43',
    OITA: '44',
    MIYAZAKI: '45',
    KAGOSHIMA: '46',
    OKINAWA: '47',
    OTHER: '999',
  } as const;
  const prefectureCodes = Object.values(PrefectureCode);
  type prefectureCodesType = typeof prefectureCodes[number];
  /** 都道府県 */
  export const Prefecture: Record<prefectureCodesType, string> = {
    [PrefectureCode.HOKKAIDO]: '北海道',
    [PrefectureCode.AOMORI]: '青森県',
    [PrefectureCode.IWATE]: '岩手県',
    [PrefectureCode.MIYAGI]: '宮城県',
    [PrefectureCode.AKITA]: '秋田県',
    [PrefectureCode.YAMAGATA]: '山形県',
    [PrefectureCode.FUKUSHIMA]: '福島県',
    [PrefectureCode.IBARAKI]: '茨城県',
    [PrefectureCode.TOCHIGI]: '栃木県',
    [PrefectureCode.GUNMA]: '群馬県',
    [PrefectureCode.SAITAMA]: '埼玉県',
    [PrefectureCode.CHIBA]: '千葉県',
    [PrefectureCode.TOKYO]: '東京都',
    [PrefectureCode.KANAGAWA]: '神奈川県',
    [PrefectureCode.NIIGATA]: '新潟県',
    [PrefectureCode.TOYAMA]: '富山県',
    [PrefectureCode.ISHIKAWA]: '石川県',
    [PrefectureCode.FUKUI]: '福井県',
    [PrefectureCode.YAMANASHI]: '山梨県',
    [PrefectureCode.NAGANO]: '長野県',
    [PrefectureCode.GIFU]: '岐阜県',
    [PrefectureCode.SHIZUOKA]: '静岡県',
    [PrefectureCode.AICHI]: '愛知県',
    [PrefectureCode.MIE]: '三重県',
    [PrefectureCode.SHIGA]: '滋賀県',
    [PrefectureCode.KYOTO]: '京都府',
    [PrefectureCode.OSAKA]: '大阪府',
    [PrefectureCode.HYOGO]: '兵庫県',
    [PrefectureCode.NARA]: '奈良県',
    [PrefectureCode.WAKAYAMA]: '和歌山県',
    [PrefectureCode.TOTTORI]: '鳥取県',
    [PrefectureCode.SHIMANE]: '島根県',
    [PrefectureCode.OKAYAMA]: '岡山県',
    [PrefectureCode.HIROSHIMA]: '広島県',
    [PrefectureCode.YAMAGUCHI]: '山口県',
    [PrefectureCode.TOKUSHIMA]: '徳島県',
    [PrefectureCode.KAGAWA]: '香川県',
    [PrefectureCode.EHIME]: '愛媛県',
    [PrefectureCode.KOCHI]: '高知県',
    [PrefectureCode.FUKUOKA]: '福岡県',
    [PrefectureCode.SAGA]: '佐賀県',
    [PrefectureCode.NAGASAKI]: '長崎県',
    [PrefectureCode.KUMAMOTO]: '熊本県',
    [PrefectureCode.OITA]: '大分県',
    [PrefectureCode.MIYAZAKI]: '宮崎県',
    [PrefectureCode.KAGOSHIMA]: '鹿児島県',
    [PrefectureCode.OKINAWA]: '沖縄県',
    [PrefectureCode.OTHER]: 'その他',
  } as const;

  /** ユーザ属性コード */
  export const UserAttributeCode = {
    /** 選手 */
    PLAYER: 'PLAYER',
    /** 指導者 */
    COACH: 'COACH',
    /** 保護者 */
    GUARDIAN: 'GUARDIAN',
    /** その他 */
    OTHER: 'OTHER',
    /** 未設定 */
    NONE: 'NONE',
  } as const;
  /** ユーザ属性 */
  export const UserAttribute: Record<keyof typeof UserAttributeCode, string> = {
    [UserAttributeCode.PLAYER]: '選手',
    [UserAttributeCode.COACH]: '指導者',
    [UserAttributeCode.GUARDIAN]: '保護者',
    [UserAttributeCode.OTHER]: 'その他',
    [UserAttributeCode.NONE]: '未設定',
  } as const;

  /** ユーザステータスコード */
  export const UserStatusCode = {
    /** 有効 */
    VALID: 'VALID',
    /** 有効（カード未登録） */
    VALID_UNREGISTERED: 'VALID_UNREGISTERED',
    /** 有効（退会処理中） */
    VALID_WITHDRAWING: 'VALID_WITHDRAWING',
    /** 無効（有効期限切） */
    INVALID_EXPIRED: 'INVALID_EXPIRED',
    /** 無効（退会済み） */
    INVALID_WITHDRAWN: 'INVALID_WITHDRAWN',
  } as const;
  /** ユーザステータス */
  export const UserStatus: Record<keyof typeof UserStatusCode, string> = {
    [UserStatusCode.VALID]: '有効',
    [UserStatusCode.VALID_UNREGISTERED]: '有効（カード未登録）',
    [UserStatusCode.VALID_WITHDRAWING]: '有効（退会処理中）',
    [UserStatusCode.INVALID_EXPIRED]: '無効（有効期限切）',
    [UserStatusCode.INVALID_WITHDRAWN]: '無効（退会済み）',
  } as const;

  /** 評価ステータス（GOOD, BADボタン） */
  export const EvaluationStatusCode = {
    /** 良い */
    GOOD: 'GOOD',
    /** 悪い */
    BAD: 'BAD',
    /** 未選択 */
    NONE: 'NONE',
  } as const;

  /** 評価アイテムタイプ */
  export const EvaluationType = {
    /** セミナー動画 */
    SEMINAR: 'SEMINAR',
    /** ドリル動画 */
    SWIM_DRILL: 'SWIM_DRILL',
    /** 陸トレ動画 */
    DRY_LAND_TRAINING: 'DRY_LAND_TRAINING',
    /** メニュー動画 */
    MENU: 'MENU',
    /** 未選択 */
    NONE: 'NONE',
  } as const;

  /** お気に入りアイテムタイプ */
  export const FavoriteItemType = {
    /** セミナー動画 */
    SEMINAR: 'SEMINAR',
    /** ドリル動画 */
    SWIM_DRILL: 'SWIM_DRILL',
    /** 陸トレ動画 */
    DRY_LAND_TRAINING: 'DRY_LAND_TRAINING',
    /** メニュー動画 */
    MENU: 'MENU',
    /** 未選択 */
    NONE: 'NONE',
  } as const;

  /** タグアイテムタイプ */
  export const ItemTagType = {
    /** セミナー動画 */
    SEMINAR: 'SEMINAR',
    /** ドリル動画 */
    SWIM_DRILL: 'SWIM_DRILL',
    /** 陸トレ動画 */
    DRY_LAND_TRAINING: 'DRY_LAND_TRAINING',
    /** メニュー動画 */
    MENU: 'MENU',
    /** 未選択 */
    NONE: 'NONE',
  } as const;

  /** ページコンテンツアイテムタイプ */
  export const PageContentItemType = {
    /** セミナー動画 */
    SEMINAR: 'SEMINAR',
    /** ドリル動画 */
    SWIM_DRILL: 'SWIM_DRILL',
    /** 陸トレ動画 */
    DRY_LAND_TRAINING: 'DRY_LAND_TRAINING',
    /** メニュー動画 */
    MENU: 'MENU',
  } as const;

  /** ページコンテンツ */
  export const PageContents = {
    /** セミナー動画 */
    [PageContentItemType.SEMINAR]: 'セミナー動画',
    /** ドリル動画 */
    [PageContentItemType.SWIM_DRILL]: '水中ドリル',
    /** 陸トレ動画 */
    [PageContentItemType.DRY_LAND_TRAINING]: '陸トレ',
    /** メニュー動画 */
    [PageContentItemType.MENU]: 'メニュー',
  } as const;

  /** ページパス種類 (主要なものだけ定義。必要が出てきたら定義追加する) */
  export const PagePathType = {
    TOP: 'TOP',
    /** セミナー動画 */
    SEMINAR: 'SEMINAR',
    /** ドリル動画 */
    SWIM_DRILL: 'SWIM_DRILL',
    /** 陸トレ動画 */
    DRY_LAND_TRAINING: 'DRY_LAND_TRAINING',
    /** メニュー動画 */
    MENU: 'MENU',
  } as const;

  /** ページパス (主要なものだけ定義。必要が出てきたら定義追加する) */
  export const PagePaths = {
    [PagePathType.TOP]: { PATH: '/top', NAME: 'ホーム' },
    /** セミナー動画 */
    [PagePathType.SEMINAR]: { PATH: '/seminar', NAME: 'セミナー動画' },
    /** ドリル動画 */
    [PagePathType.SWIM_DRILL]: { PATH: '/swim_drill', NAME: '水中ドリル' },
    /** 陸トレ動画 */
    [PagePathType.DRY_LAND_TRAINING]: {
      PATH: '/dryland_training',
      NAME: '陸トレ',
    },
    /** メニュー動画 */
    [PagePathType.MENU]: { PATH: '/menu', NAME: 'メニュー' },
  } as const;

  /** 職業コード */
  export const OccupationCode = {
    /** スイマー */
    SWIMMER: 'SWIMMER',
    /** コーチ(スイミングスクール) */
    SWIMMING_SCHOOL_COACH: 'SWIMMING_SCHOOL_COACH',
    /** コーチ(中学 or 高校の部活) */
    CLUB_COACH: 'CLUB_COACH',
    /** コーチ(大学) */
    COLLEGE_COACH: 'COLLEGE_COACH',
    /** スイマーの保護者 */
    GUARDIAN: 'GUARDIAN',
    /** その他水泳関連 */
    OTHER: 'OTHER',
  } as const;

  /** 職業 */
  export const Occupations = {
    /** スイマー */
    [OccupationCode.SWIMMER]: 'スイマー',
    /** コーチ(スイミングスクール) */
    [OccupationCode.SWIMMING_SCHOOL_COACH]: 'コーチ(スイミングスクール)',
    /** コーチ(中学・高校の部活) */
    [OccupationCode.CLUB_COACH]: 'コーチ(中学・高校の部活)',
    /** コーチ(大学) */
    [OccupationCode.COLLEGE_COACH]: 'コーチ(大学)',
    /** スイマーの保護者 */
    [OccupationCode.GUARDIAN]: 'スイマーの保護者',
    /** その他水泳関連 */
    [OccupationCode.OTHER]: 'その他水泳関連',
  } as const;

  /** アンケート設問タイプ */
  export enum QuestionnaireItemType {
    /** チェックボックス */
    CHECK_BOX = 'CHECK_BOX',
    /** セレクト */
    SELECT = 'SELECT',
    /** ラジオボタン */
    RADIO = 'RADIO',
    /** テキストボックス */
    TEXT = 'TEXT',
    /** テキストエリア */
    TEXT_AREA = 'TEXT_AREA',
  }

  /** バナー設置場所タイプ */
  export enum BannerLocationType {
    /** トップページ（ホーム画面） */
    TOP_PAGE = 'TOP_PAGE',
  }

  /** バナー設置場所 */
  export const BannerLocation = {
    /** トップページ（ホーム画面） */
    [BannerLocationType.TOP_PAGE]: 'ホーム',
  } as const;
}
