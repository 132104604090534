import { atom } from 'recoil';
import { UserStatusCodeType } from '@/types/codeType';
import { UserSettings } from '@/types/userSettings';

export type UserProps = {
  uid: string;
  status: UserStatusCodeType;
  settings: UserSettings;
};

export type AuthProps = {
  status:
    | 'initializing'
    | 'none'
    | 'authenticating'
    | 'requiredInitialPayment'
    | 'requiredSubscriptionPayment'
    | 'authenticated';
  user?: UserProps;
};

export const authStore = atom<AuthProps>({
  key: 'trashareAuth',
  default: {
    status: 'initializing',
  },
});

/** 決済完了時のCompleteEvent送信メールアドレス */
export const emailStore = atom<string | undefined>({
  key: 'emailStore',
  default: undefined,
});
