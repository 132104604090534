import { FC, ReactNode } from 'react';
import useAuth from '@/hooks/useAuth';

type Props = {
  children: ReactNode;
};

export const AuthProvider: FC<Props> = ({ children }) => {
  useAuth();
  return <>{children}</>;
};
