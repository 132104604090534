import { extendTheme } from "@chakra-ui/react";
export const theme = extendTheme({
  colors: {
    transparent: "transparent",
    base: "#1a1a1a",
    secondary: "#0078d8",
    textDark: "#292b32",
    textLight: "#ffffff",
    placeholder: "#aaaaaa",
    stroke: "#e4e4e4",
    muted: "#f5f5f5",
    error: "#ff6060",
    success: "#08b99b",
    accent: "#f0c119",

    lightOverlay: "rgba(255, 255, 255, 0.4)",
    darkOverlay: "rgba(0, 0, 0, 0.4)",
  },

  breakpoints: {
    md: "600px",
  },

  fonts: {
    body: "Helvetica Neue, Arial, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif",
    heading:
      "Helvetica Neue, Arial, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif",
    mono: "Helvetica Neue, Arial, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif",
  },

  fontSizes: {
    "2xs": "10px",
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "20px",
    xl: "24px",
    "2xl": "28px",
  },

  sizes: {},
});
