/**
 * @desc Sentryの共通設定を行う
 * @license Copyright Trashare
 */
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export namespace SentryConfig {
  /**
   * @desc Sentryの初期化
   */
  export const init = (): void => {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: process.env.NEXT_PUBLIC_APP_ENV,
      tracesSampleRate: 0.001,
      maxValueLength: 20000
    });
  };
}
